import { isDefined } from "@clipboard-health/util-ts";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { Alert } from "@mui/material";
import type { Facility } from "@src/lib/interface/src/lib/shift";

interface MaxWorkedHoursProps {
  facility: Facility;
}

export function MaxWorkedHours(props: MaxWorkedHoursProps) {
  const { facility } = props;
  const getMaxHoursWarningLabel = ({
    maxAllowedWorkConsecutiveHours,
    maxAllowedWorkHoursPerWeek,
  }: Pick<Facility, "maxAllowedWorkConsecutiveHours" | "maxAllowedWorkHoursPerWeek">) => {
    if (isDefined(maxAllowedWorkConsecutiveHours) && isDefined(maxAllowedWorkHoursPerWeek)) {
      return `This facility does not allow professionals to book more than ${maxAllowedWorkConsecutiveHours} consecutive hours of work or more than ${maxAllowedWorkHoursPerWeek} hours of work per week`;
    } else if (isDefined(maxAllowedWorkHoursPerWeek)) {
      return `This facility does not allow professionals to book more than ${maxAllowedWorkHoursPerWeek} hours of work per week`;
    } else if (isDefined(maxAllowedWorkConsecutiveHours)) {
      return `This facility does not allow professionals to book more than ${maxAllowedWorkConsecutiveHours} consecutive hours of work`;
    }
  };

  const warningLabel = getMaxHoursWarningLabel(facility);

  return warningLabel ? (
    <Alert severity="warning" icon={<EventBusyIcon />} aria-label="Max worked hours warning">
      {warningLabel}
    </Alert>
  ) : null;
}
